.result {
    width: 250px;
    padding: 16px;
    border: 1px solid #eee;
    box-shadow: 0 2px 3px #ccc;
    margin: 10px;
    margin-bottom: 18px;
    box-sizing: border-box;
    
    /* cursor: pointer; */
}

.result:hover,
.result:active {
    background-color: #f2f2f2;
}

.title-style {
    vertical-align: middle;
}

.card-img-top {
    width: auto;
    min-width: 10em;
    max-width: 20em;
    height: auto;
    max-height: 12em;
    vertical-align: middle;
}

.centered-content {
    display: flex;
    flex-direction: column; 
    padding-left: 16px;
    height: 100%; 
  }

  .add-to-cart-button {
    background-color: #F7C52B; 
    color: #000; 
    padding: 10px 20px;
    margin: 14px;
    width: 65%;
    align-self: center;
    border: none; 
    font-size: 16px; 
    font-weight: bold; 
    cursor: pointer; 
    text-transform: uppercase;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }  