/* Detail Styles */
.results-info {
    margin: 4px;
    display: inline; 
}

.results {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-left: 0em;
    margin-right: 0em;
    padding-top: 20px;
}

.results .result {
    width: 20rem;
    max-height: 32rem;
    border: 1px solid gray;
}

.results .result a {
    color: #181818; 
}

.centered-symbol {
    font-size: 20px;
    cursor: pointer;
    display: inline;
    margin-left: 10px;
  }